import { ToastContainer } from "react-toastify";

import { SessionProvider } from "next-auth/react";

import "styles/globals.css";
import "react-toastify/dist/ReactToastify.css";
import Layout from "components/common/Layout";
import { useEffect } from "react";
import { saveUTMParams } from "utils/storage";

const MyApp = ({ Component, pageProps, router: { pathname } }) => {
  useEffect(() => {
    saveUTMParams();
  }, []);

  const isDesignPage =
    pathname === "/create/[orderId]/[designId]/design" ||
    pathname === "/stock-shapes/[orderId]/[designId]/design";

  return (
    <>
      <SessionProvider>
        <Layout hideHeaderOnMobile={isDesignPage}>
          <Component {...pageProps} />
        </Layout>
      </SessionProvider>

      <ToastContainer autoClose={3000} theme="colored" />
    </>
  );
};

export default MyApp;
